<template>
  <v-row>
    <!-- header -->
    <mini-header title="SIRE" :dense="true" :breadcrumbs="breadcrumbs" :hiddenTab="true" />
    <!-- end header -->
     <v-container style="min-height: 75vh;">
       <v-row class="fill-height" align="center" justify="center">
        <PermissionDenied v-if="!$helpers.hasSomePermission(['download_sire'])" />
        <v-col cols="8" class="px-0 mx-auto" v-else style="min-width:580px">
          <v-empty-state type="sire" id="sire" :height="200"  customClass="mt-8" :hiddenDescription="true" :isFree="true" title="Selecciona el rango de fechas que deseas descargar" description="El rango no debe ser superior a 7 días">
            <template v-slot:actions>
              <v-row class="mt-5" align="center" justify="center" no-gutters>
                <div class="mx-2 pa-0 text-left" style="width: 175px;">
                  <span class="d-block grey-700--text body-2">Desde</span>
                  <v-menu v-model="startDateMenu" :close-on-content-click="false" offset-y :nudge-bottom="10" transition="slide-y-transition" max-width="280" z-index="1000">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="startDate" v-bind="attrs" v-on="on" append-icon="mdi-calendar-blank" placeholder="dd-mm-aaaa" outlined single-line dense readonly hide-details />
                    </template>
                    <v-date-picker v-model="startDate" @input="startDateMenu=false" :max="minDateStart" no-title :first-day-of-week="1" type="date" locale="es" width="270" color="blue-500">
                      <v-row class="pt-0 mb-1 px-5">
                        <v-btn class="body-1" :ripple="false" text color="blue-500" @click="() => { startDate=null; startDateMenu=false }">Limpiar</v-btn>
                      </v-row>
                    </v-date-picker>
                  </v-menu>
                </div>
                <div class="mx-2 pa-0 text-left" style="width: 175px;">
                  <span class="d-block grey-700--text body-2">Hasta</span>
                  <v-menu v-model="endDateMenu" :close-on-content-click="false" offset-y :nudge-bottom="10" transition="slide-y-transition" max-width="280" z-index="1000">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="endDate" v-bind="attrs" v-on="on" append-icon="mdi-calendar-blank" placeholder="dd-mm-aaaa" outlined single-line dense readonly hide-details />
                    </template>
                    <v-date-picker v-model="endDate" @input="endDateMenu=false" :min="startDate" :max="maxDate" no-title :first-day-of-week="1" type="date" locale="es" width="270" color="blue-500">
                      <v-row class="mb-1 px-5">
                        <v-btn class="body-1" :ripple="false" text color="blue-500"  @click="() => { endDate=null; endDateMenu=false }">Limpiar</v-btn>
                      </v-row>
                    </v-date-picker>
                  </v-menu>
                </div>
                <div class="mx-2 pa-0 pt-4">
                  <v-btn @click="createExport" color="blue-500" :loading="loading" :ripple="false" :width="153">Descargar</v-btn>
                </div>
              </v-row>
            </template>
          </v-empty-state>
          <v-col class="text-center">
            <!-- alert -->
            <v-alert class="inf-alert-blue blue-500--text rounded-md mt-2 mx-auto" :height="56" :width="540" dense text>
              <div class="d-flex align-center pa-0 my-0">
                <v-icon class="pt-1" color="blue-500" size="22">mdi-information-outline</v-icon>
                <div class="ml-4 mt-1 d-flex align-center text-left">
                  <span class="body-2">Esta acción
                    <span class="font-weight-bold">podría tardar varios minutos</span> en completarse. No cierres el navegador hasta completar la descarga.
                  </span>
                </div>
              </div>
            </v-alert>
            <!-- end alert -->
          </v-col>
        </v-col>
      </v-row>
    </v-container>
  </v-row>
</template>
<script>
import moment from 'moment'
import { saveAs } from 'file-saver'
import MiniHeader from '@/components/commons/MiniHeader'
import PermissionDenied from '@/modules/http/views/PermissionDenied'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
import VEmptyState from '@/components/commons/VEmptyState'

export default {
  components: {
    MiniHeader,
    PermissionDenied,
    SkeletonList,
    VEmptyState
  },
  data () {
    return {
      loading: false,
      startDateMenu: false,
      endDateMenu: false,
      startDate: null,
      endDate: null,
      breadcrumbs: {
        main: 'Emisión',
        children: [
          {
            text: 'SIRE'
          }
        ]
      }
    }
  },
  computed: {
    maxDate () {
      const start = moment().subtract(7, 'days').format('YYYY-MM-DD')
      const end = moment(this.startDate).add(6, 'days').format()
      if (moment(start).isBefore(moment(end))) {
        return start
      }
      return moment(this.startDate ?? null).add(6, 'days').format()
    },
    maxDateStart () {
      return moment().format('YYYY-MM-DD')
    },
    minDateStart () {
      return moment().subtract(7, 'days').format('YYYY-MM-DD')
    }
  },
  watch: {
    startDate () {
      this.endDate = null
    }
  },
  methods: {
    createExport () {
      this.loading = true
      let docNotFound = []
      const start = moment(this.startDate, 'YYYY-MM-DD')
      const end = moment(this.endDate, 'YYYY-MM-DD')
      const range = []

      if (this.startDate && this.endDate) {
        while (start.isSameOrBefore(end)) {
          range.push(start.format('YYYY-MM-DD'))
          start.add(1, 'day')
        }
      } else range.push(start)
      Promise.all((range).map((item) => {
        return this.$store.dispatch('accounts/LIST', {
          resource: `documents/daily-app/${moment(item).format('YYYYMMDD')}`,
          responseType: 'blob'
        })
        .then((response) => {
          saveAs(new Blob([response.data], { type: response.headers['content-type'] }), response.headers['content-disposition'].match(/"([^"]*)"/g)[0].replace(/"/g, ''))
        })
        .catch(() => {
          docNotFound.push(moment(item).format('YYYY-MM-DD'))
        })
      }))
        .then(() => {
          this.loading = false
        if (docNotFound?.length) this.$dialog.message.error(`El archivo correspondiente al ${docNotFound.join(', ')} no está disponible.`, { timeout: 10000 })
      })
    }
  }
}
</script>